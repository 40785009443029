import {
    AppBar,
    Avatar,
    Box,
    Button,
    Toolbar,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef } from "react";
  import logoRaghav from "../assets/images/logoRaghav.gif";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import LinkedInIcon from "@mui/icons-material/LinkedIn";
  import img1 from "../assets/Shaadi/1.png";
  import img2 from "../assets/Shaadi/2.png";
  import img3 from "../assets/Shaadi/3.png";
  import img4 from "../assets/Shaadi/4.png";
  import img5 from "../assets/Shaadi/5.png";
  import img6 from "../assets/Shaadi/6.png";
  import img7 from "../assets/Shaadi/7.png";
  import img8 from "../assets/Shaadi/8.png";
  import img9 from "../assets/Shaadi/9.png";
  import img10 from "../assets/Shaadi/10.png";
  import img11 from "../assets/Shaadi/11.png";
  import img12 from "../assets/Shaadi/12.png";
  import img13 from "../assets/Shaadi/13.png";
  import img14 from "../assets/Shaadi/14.png";
  import img15 from "../assets/Shaadi/15.png";
  import img16 from "../assets/Shaadi/16.png";
  import img17 from "../assets/Shaadi/17.png";
  import img18 from "../assets/Shaadi/18.png";
  import img19 from "../assets/Shaadi/19.png";
  import img20 from "../assets/Shaadi/20.png";
  import img21 from "../assets/Shaadi/21.png";
  import img22 from "../assets/Shaadi/22.png";
  import img23 from "../assets/Shaadi/23.png";
  import behance from "../assets/images/Behance.png";
  import { Link } from "react-router-dom";
  import "./projextDesc.css";
  import InstagramIcon from "@mui/icons-material/Instagram";
  
  import logo from "../assets/icons/Logo.png";
  const Project6 = () => {
    
    const topOfPageRef = useRef(null);
  
    useEffect(() => {
      // Scroll to top of the page when the component is mounted
      window.scrollTo(0, 0);
      
      // Automatically click on "Top of Page" link after a short delay (e.g., 500ms)
      const timeoutId = setTimeout(() => {
        if (topOfPageRef.current) {
          topOfPageRef.current.click();
        }
      }, 500);
  
      // Clean up the timeout on component unmount
      return () => clearTimeout(timeoutId);
    }, []);
  
    return (
      <div className="projectDescription">
        <div
          id="top"
          className="grid"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            gap: "20px",
          }}
        >
          {/* First column */}
          <div
            className="verticalBar"
            style={{
              "@media screen and (max-width: 768px)": { display: "none" },
            }}
          >
            <div>
              <a href="/">
                <div className="logo">
                  <img src={logo} alt="GIF image" />
                </div>
              </a>
            </div>
          </div>
  
          {/* Second column */}
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                flexGrow: 1,
                width: "100%",
                margin: "auto",
                display: "block",
                "@media screen and (max-width: 768px)": {
                  textAlign: "center", // Center align the icon on mobile screens
                },
                "@media screen and (min-width: 769px)": {
                  textAlign: "left", // Left align the icon on desktop screens
                },
              }}
            >
              <Button LinkComponent={Link} to="/work">
                <ArrowBackIcon
                  className="arrowIcon"
                  sx={{
                    color: "#0101F6",
                    paddingTop: "50px",
                    fontSize: { xs: "80px", sm: "30px" },
                  }}
                />
              </Button>
            </Box>
            <Box className="ProjDesc" sx={{ flexGrow: 1, marginTop: "40px" }}>
              <Typography
                fontFamily={"Poppins"}
                fontWeight={700}
                fontSize={{ xs: "30px", sm: "34px" }}
                sx={{ color: "#0101F3" }}
              >
                Shaadi.com
              </Typography>
              <Typography
                fontFamily={"Poppins"}
                fontWeight={500}
                fontSize={{ xs: "17px", sm: "20px" }}
                lineHeight={3}
                sx={{ color: "#0101F3" }}
              >
                <i>UX UI | Case study | App Design</i>
              </Typography>
              <Box sx={{ flexGrow: 1, width: { xs: "90%", sm: "80%" } }}>
                <Typography
                  variant="body"
                  fontFamily={"Poppins"}
                  fontWeight={500}
                  fontSize={{ xs: "14px", sm: "14px" }}
                  lineHeight={-1}
                  sx={{ color: "#000000" }}
                >
                  This UX case study focuses on designing a customized onboarding 
                  experience for low-intent users of the Shaadi.com app—individuals 
                  who have registered but are not actively seeking a life partner. 
                  The project aimed to understand their behavior, address their 
                  unique needs, and create an engaging experience that encourages 
                  them to explore the platform while building long-term interest and trust.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                "@media screen and (max-width: 450px)": { width: "100%" },
              }}
            >
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img1}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img2}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img3}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img4}
              />
  
              <img
                id="branding"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img5}
              />
  
              <img
                id="research"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img6}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img7}
              />

<img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img8}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img9}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img10}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img11}
              />
  
              <img
                id="branding"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img12}
              />
  
              <img
                id="solution"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img13}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img14}
              />

              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img15}
              />

<img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img16}
              />
  
              <img
                alt="image1"
                id="interface"
                style={{ width: "100%",  height: "auto" }}
                src={img17}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img18}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img19}
              />
  
              <img
                id="branding"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img20}
              />
  
              <img
                id="research"
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img21}
              />
  
              <img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img22}
              />

<img
                alt="image1"
                style={{ width: "100%",  height: "auto" }}
                src={img23}
              />
            </Box>
          </div>
  
          {/* Third column */}
          <div
            className="navigationbar"
            style={{
              "@media screen and (max-width: 768px)": { display: "none" },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Typography
                fontFamily={"Krona One"}
                fontSize={{ xs: "10px", sm: "12px", paddingTop: "10px" }}
              >
                <a
                  ref={topOfPageRef}
                  href="#top"
                  onMouseEnter={(e) => (e.target.style.color = "#AAE800")}
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Top of Page
                </a>
              </Typography>
              <Typography
                fontFamily={"Krona One"}
                fontSize={{ xs: "10px", sm: "12px", paddingTop: "10px" }}
              >
                <a
                  href="#research"
                  onMouseEnter={(e) => (e.target.style.color = "#AAE800")}
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Research{" "}
                </a>
              </Typography>
              <Typography
                fontFamily={"Krona One"}
                fontSize={{ xs: "10px", sm: "12px", paddingTop: "10px" }}
              >
                <a
                  href="#solution"
                  onMouseEnter={(e) => (e.target.style.color = "#AAE800")}
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Solution{" "}
                </a>
              </Typography>
              <Typography
                fontFamily={"Krona One"}
                fontSize={{ xs: "10px", sm: "12px", paddingTop: "10px" }}
              >
                <a
                  href="#interface"
                  onMouseEnter={(e) => (e.target.style.color = "#AAE800")}
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Interface{" "}
                </a>
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <Box
            width="100%"
            margin="auto"
            marginTop={"-5%"}
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            sx={{ background: "black" }}
          >
            <Typography
              marginTop="10%"
              fontFamily={"Krona One"}
              fontWeight={100}
              color="white"
              fontSize={"21px"}
            >
              Thanks for watching
            </Typography>
            <Typography
              fontWeight={200}
              fontFamily={"Work Sans"}
              color="white"
              fontSize={"20.5px"}
            >
              {" "}
              💖
            </Typography>
  
            <Typography
              marginTop="2%"
              marginBottom="2%"
              fontFamily={"Krona One"}
              fontWeight={100}
              color="#787878"
              width={"40%"}
              textAlign={"center"}
              fontSize={"10.5px"}
            >
              Calling all feedback ninjas! I need your stealthy insights to level
              up!
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <a href="https://instagram.com/raghav._.xd?igshid=OGQ5ZDc2ODk2ZA==">
                <InstagramIcon sx={{ color: "#E3E4E68C", margin: 3 }} />
              </a>
              <a href="https://www.linkedin.com/in/-raghavsharma/">
                <LinkedInIcon sx={{ color: "#E3E4E68C", margin: 3 }} />
              </a>
              <a href="https://www.behance.net/raghavsharma3">
                <img
                  alt="image1"
                  src={behance}
                  style={{
                    height: "32px",
                    width: "32px",
                    margin: "20px",
                  }}
                ></img>
              </a>{" "}
            </Box>
          </Box>
        </div>
      </div>
    );
  };
  
  export default Project6;
  